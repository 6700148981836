import { Button, message } from 'antd';
import React from 'react';
import PopUpComponent from './PopUpComponent';
import { useTranslation } from 'react-i18next';
import { changeStatusReferral } from 'modules/referral/src/graphql/model/referralMutations';

const ConditionButtons = ({ ...props }) => {
  const { t } = useTranslation();
  const isSaveButtonDisabled =
    props.accrualPercentage === props.initialAccrualPercentage && props.referralBonus === props.initialReferralBonus; 
  async function saveNewReferral() {
    const data = {
      accrualPercentage: props.accrualPercentage,
      referralBonus: props.referralBonus,
    };
    const activateReferral = await changeStatusReferral(props.client, data);
    if (activateReferral) {
      message.success(t('modules.referral_details-modal.success'));
    } else {
      message.error(t('modules.referral_details-modal.error'));
    }
  }
  return (
    <div className="referral-buttons">
      <Button className="route-content_loyalty-deactivate-button" onClick={props.showModal}>
        {t('modules.modal_deactivation_warning.button.deactivate_program')}
      </Button>
      <PopUpComponent
        isVisible={props.isVisible}
        setIsVisible={props.setIsVisible}
        t={t}
        deactivateReferral={props.deactivateReferral}
      />

      <Button
        type="submit"
        className={
          (isSaveButtonDisabled)
            ? 'route-content_loyalty-save-button'
            : 'route-content_loyalty-save-button route-content_loyalty-save-button_active'
        }
        onClick={saveNewReferral}
        disabled={isSaveButtonDisabled}
      >
        {t('modules.conditions_buttons.save_changes.text')}
      </Button>
    </div>
  );
};

export default ConditionButtons;
