import React, { useEffect, useState } from 'react';
import './ReferralPage.scss';
import ReferralActivate from './ReferralActivate/ReferralActivate';
import Loader from 'common/Loader/Loader';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import ApolloClient from 'apollo-client';
import ReferralConditions from './ReferralСonditions/ReferralConditions';
import Coin from './img/coin.svg';
import { useTranslation } from 'react-i18next';
import { getStatus, getStatusActiveReferral } from '../../graphql/model/referralQueries';
import { changeStatusReferral } from '../../graphql/model/referralMutations';

const ReferralPage = (props: { authClient: AuthClient; client: ApolloClient<any> }) => {
  const { t } = useTranslation();
  const { authClient, client } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(false);

  let [showConditions, setShowConditions] = useState({
    accrualPercentage: 0,
    referralBonus: 0,
    isActive: false,
  });

  useEffect(() => {
    const activateStatus = async () => {
      try {
        const isActiveValue = await getStatus(client);
        setIsActive(isActiveValue);
        if (!isActiveValue) {
          const data = { isActive: false };
          setShowConditions((prevState) => ({
            ...prevState,
            isActive: false,
          }));
          await changeStatusReferral(client, data);
          return;
        }
        const activateReferral = await getStatusActiveReferral(client);
        if (activateReferral) {
          setShowConditions({
            isActive: activateReferral.isActive,
            accrualPercentage: activateReferral.accrualPercentage,
            referralBonus: activateReferral.referralBonus,
          });
        }
      } finally {
        setLoading(false);
      }
    };
    activateStatus();
  }, []);

  return (
    <div className="refwrapper">
      <h1>{t('modules.referral.header')}</h1>

      <div className="route-box">
        {loading ? (
          <Loader />
        ) : showConditions.isActive ? (
          <ReferralConditions
            authClient={authClient}
            client={client}
            setShowConditions={setShowConditions}
            showConditions={showConditions}
          />
        ) : (
          <ReferralActivate
            authClient={authClient}
            client={client}
            setIsActive={setIsActive}
            setShowConditions={setShowConditions}
            showConditions={showConditions}
            isActive={isActive}
          />
        )}
        {!showConditions.isActive && !loading && (
          <>
            <img src={Coin} alt="coin" className="large" />
            <img src={Coin} alt="coin" className="medium" />
            <img src={Coin} alt="coin" className="small" />
          </>
        )}
      </div>
    </div>
  );
};

export default ReferralPage;
