import { InputConditionProps } from 'modules/referral/src/graphql/model/referralModel';
import React from 'react';

const InputCondition: React.FC<InputConditionProps> = ({ value, onChange, maxValidValue }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    const validValue = parseInt(numericValue, 10);
    if (!isNaN(validValue) && validValue >= 1 && validValue <= maxValidValue) {
      onChange(validValue);  
    } else if (numericValue === '') {
      onChange(0);  
    }
  };

  return (
    <input
      className="referral-input"
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputCondition;