import React, { useState } from 'react';
import './ReferralConditions.scss';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import ConditionDetails from '../common/Conditions/ConditionDetails';
import ConditionButtons from '../common/Conditions/ConditionButtons';
import { changeStatusReferral } from 'modules/referral/src/graphql/model/referralMutations';

const ReferralConditions = ({ authClient, client, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const showModal = () => setIsVisible(true);
  const [accrualPercentage, setAccrualPercentage] = useState(props.showConditions.accrualPercentage);
  const [referralBonus, setReferralBonus] = useState(props.showConditions.referralBonus);
  const [initialAccrualPercentage] = useState(props.showConditions.accrualPercentage);
  const [initialReferralBonus] = useState(props.showConditions.referralBonus);

  async function deactivateReferral() {
    const data = { isActive: false };
    const activateReferral = await changeStatusReferral(client, data);
    if (activateReferral) {
      props.setShowConditions((prev) => ({ ...prev, isActive: activateReferral.isActive }));
      message.success(t('modules.referral_condition-modal.success'));
    } else {
      message.error(t('modules.referral_condition-modal.error'));
    }
  }

  return (
    <div className="referral-conditions">
      <p className="referral-title">{t('modules.referral_conditions.title')}</p>
      <ConditionDetails
        accrualPercentage={accrualPercentage}
        setAccrualPercentage={setAccrualPercentage}
        referralBonus={referralBonus}
        setReferralBonus={setReferralBonus}
      />
      <ConditionButtons
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        deactivateReferral={deactivateReferral}
        setShowConditions={props.setShowConditions}
        showModal={showModal}
        client={client}
        value={props.activateReferral}
        accrualPercentage={accrualPercentage}
        referralBonus={referralBonus}
        initialAccrualPercentage={initialAccrualPercentage}
        initialReferralBonus={initialReferralBonus} 
      />
    </div>
  );
};

export default ReferralConditions;
