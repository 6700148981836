import gql from 'graphql-tag';

export const GET_ORDERS = gql`
  query getOrders($searchString: String, $page: Int, $filterStatuses: [OrderStatus!]) {
    order {
      getList(
        filter: { usersQuickSearch: $searchString, statuses: $filterStatuses }
        paging: { limit: 15, page: $page }
      ) {
        total
        list {
          id
          visibleId
          purchaseTime
          additionalData {
            paymentAdapter
            amount {
              electronic
              cash
              loyalty
            }
          }
          user {
            person {
              name
              surname
              patronymic
              contacts {
                type
                value
              }
            }
            login
          }
          priceWithDiscount
          status
          paymentPlace
        }
      }
    }
  }
`;
export const GET_SUM = gql`
  query($filter: TicketFilter!) {
    ticket {
      summary(filter: $filter) {
        totals {
          count
          sumDiscount
        }
      }
    }
  }
`;
export const SEARCH_USERS = gql`
  query searchUsers($quickSearch: String!) {
    users {
      getListAdvanced(filter: { quickSearch: $quickSearch }) {
        list {
          id
          person {
            name
            surname
            patronymic
            birthday
            contacts {
              type
              value
            }
          }
          login
        }
      }
    }
  }
`;
export const GET_SEASON_SUM = gql`
  query($filter: SeasonTicketFilter) {
    seasonTicket {
      summary(filter: $filter) {
        totals {
          count
          sumDiscount
        }
      }
    }
  }
`;
export const GET_MATCH_MAP = gql`
  query getEvent($id: ID!) {
    matchLocalised {
      getById(id: $id) {
        id
        status
        startTime
        venue {
          id
          map {
            publicLink
          }
          size {
            width
            height
          }
        }
        stage {
          tournament {
            title
            logo {
              publicLink
            }
          }
        }
        team1 {
          id
          title
          logo {
            publicLink
          }
        }
        team2 {
          id
          title
          logo {
            publicLink
          }
        }
      }
    }
  }
`;
export const GET_MATCH_INFO = gql`
  query($filter: TicketFilter!, $paging: Pagination!) {
    ticket {
      getList(filter: $filter, paging: $paging) {
        total
        list {
          id
          visibleId
          order {
            id
            additionalData {
              commentary
            }
          }
          orderItem {
            id
          }
          place {
            number
            row {
              number
              sector {
                title
              }
            }
          }
          user {
            login
            person {
              name
              surname
              patronymic
              contacts {
                type
                value
              }
            }
          }
          createdTime
          status
          price
          orderItem {
            price
            priceWithDiscount
          }
        }
      }
    }
  }
`;
export const GET_SEASON_TICKETS_INFO = gql`
  query($filter: SeasonTicketFilter!, $paging: Pagination!) {
    seasonTicket {
      getList(filter: $filter, paging: $paging) {
        total
        list {
          id
          visibleId
          order {
            id
            additionalData {
              commentary
            }
          }
          orderItem {
            id
          }
          place {
            number
            row {
              number
              sector {
                title
              }
            }
          }
          user {
            login
            person {
              name
              surname
              patronymic
              contacts {
                type
                value
              }
            }
          }
          purchaseDate
          status
          price
          orderItem {
            price
            priceWithDiscount
          }
        }
      }
    }
  }
`;

export const GET_TICKET_ORDER_ITEMS = gql`
  query getTicketItems($filter: TicketFilter, $paging: Pagination) {
    ticket {
      getList(filter: $filter, paging: $paging) {
        list {
          id
          user {
            id
            login
            person {
              name
              surname
              patronymic
              contacts {
                type
                value
              }
            }
          }
          place {
            id
          }
          orderItem {
            order {
              id
            }
            id
            status
            type
          }
        }
      }
    }
  }
`;

export const GET_SEASON_TICKET_ORDER_ITEMS = gql`
  query getSeasonTicketItems($filter: SeasonTicketFilter, $paging: Pagination) {
    seasonTicket {
      getList(filter: $filter, paging: $paging) {
        list {
          id
          user {
            id
            login
            person {
              name
              surname
              patronymic
              contacts {
                type
                value
              }
            }
          }
          place {
            id
          }
          orderItem {
            order {
              id
            }
            id
            status
            type
          }
        }
      }
    }
  }
`;

export const GET_MATCH_PLACES = gql`
  query getPlaces($filter: PlacePriceFilter!) {
    price {
      getPlacesDetailed(filter: $filter) {
        list {
          place {
            id
            number
            row {
              number
              sector {
                title
              }
            }
            coordinates {
              x
              y
            }
          }
          price
          status
          color
          placeStatuses {
            createdTime
          }
        }
      }
    }
  }
`;
export const GET_SEASON_PLACES = gql`
  query getSeasonPlaces($id: ID!) {
    seasonTicketDescriptor {
      getById(id: $id) {
        places {
          price
          status
          color
          place {
            id
            number
            row {
              number
              sector {
                title
              }
            }
            coordinates {
              x
              y
            }
          }
        }
        venue {
          id
          size {
            width
            height
          }
          map {
            publicLink
          }
        }
      }
    }
  }
`;
export const GET_PLACES = gql`
  query {
    venueLocalised {
      getById(id: "4ab6ad5441d446a9bbe339e2a3cfe07d") {
        id
        map {
          publicLink
        }
        places(paging: { limit: 10000 }) {
          list {
            coordinates {
              x
              y
            }
            number
            id
          }
        }
      }
    }
  }
`;

export const GET_TICKET_DETAILS = gql`
  query getExtraData($id: ID!) {
    place {
      getById(id: $id) {
        id
        number
        row {
          id
          number
          sector {
            id
            title
          }
        }
      }
    }
  }
`;
export const GET_SEASONS = gql`
  query getSeasons {
    season {
      getList {
        list {
          id
          startDate
          endDate
        }
      }
    }
  }
`;

export const GET_EVENT_CATEGORY_TEMPLATE = gql`
  query getEventCategoryTemplate($eventId: ID!) {
    priceTemplate {
      getByEvent(eventId: $eventId) {
        template {
          title
          priceCategoryTemplate {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_PRICE_TEMPLATE = gql`
  query {
    priceTemplate {
      getList {
        list {
          id
          title
        }
      }
    }
  }
`;
export const GET_MATCHES = gql`
  query getMatches($filter: MatchFilterInputLocalised) {
    matchLocalised {
      getList(filter: $filter) {
        list {
          id
          title
          status
          venue {
            id
            map {
              publicLink
            }
            size {
              width
              height
            }
          }
          team1 {
            id
            title
            logo {
              publicLink
            }
          }
          team2 {
            id
            title
            logo {
              publicLink
            }
          }
          startTime
          endTime
        }
      }
    }
  }
`;
export const GET_SEASON_TICKETS = gql`
  query getSeasonTickets($filter: SeasonTicketDescriptorFilter) {
    seasonTicketDescriptor {
      getList(filter: $filter) {
        list {
          id
          title
          status
          priceTemplate {
            id
            title
          }
          venue {
            id
            map {
              publicLink
            }
            size {
              width
              height
            }
          }
        }
      }
    }
  }
`;
export const GET_ORDER_ITEMS = gql`
  query getOrderItems($orderId: ID!) {
    order {
      getById(id: $orderId) {
        items {
          price
          item {
            ... on SeasonTicket {
              place {
                number
                row {
                  number
                  sector {
                    title
                  }
                }
              }
            }
            ... on Ticket {
              place {
                number
                row {
                  number
                  sector {
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PRICE_TEMPLATES = gql`
  query getPriceTemplates {
    priceTemplate {
      getList {
        list {
          title
          id
          prices {
            value
            priceCategory {
              title
              color
              id
            }
          }
          priceCategoryTemplate {
            id
            title
            priceCategories {
              id
              title
              color
            }
          }
        }
      }
    }
  }
`;
export const GET_TICKET_BY_PLACE = gql`
  query getTicketByPlace($filter: TicketFilter) {
    ticket {
      getOwnList(filter: $filter) {
        list {
          orderItem {
            id
          }
          order {
            id
          }
          place {
            id
          }
        }
      }
    }
  }
`;
export const GET_SEASON_TICKET_BY_PLACE = gql`
  query getSeasonTicketByPlace($filter: SeasonTicketFilter) {
    seasonTicket {
      getOwnList(filter: $filter) {
        list {
          orderItem {
            id
          }
          order {
            id
          }
          place {
            id
          }
        }
      }
    }
  }
`;
export const GET_CURRENT_SEASON = gql`
  query {
    season {
      getCurrent {
        id
      }
    }
  }
`;
export const GET_PRICE_CATEGORY_TEMPLATES = gql`
  query {
    priceCategoryTemplate {
      getList(paging: { limit: 10000 }) {
        list {
          title
          id
        }
      }
    }
  }
`;
export const GET_PRICE_CATEGORY_INFO = gql`
  query($id: ID!) {
    priceCategoryTemplate {
      getById(id: $id) {
        priceCategories {
          title
          color
          id
        }
      }
    }
  }
`;
export const GET_TICKETS_PDF = gql`
  query getTicketsPdf($ids: [ID!]!) {
    ticket {
      getPdfByIds(ids: $ids) {
        publicLink
      }
    }
  }
`;
export const GET_SEASON_TICKETS_PDF = gql`
  query getSeasonTicketsPdf($ids: [ID!]!) {
    seasonTicket {
      getPdfByIds(ids: $ids) {
        publicLink
      }
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query($id: ID!) {
    order {
      getById(id: $id) {
        id
        price
        priceWithDiscount
        additionalData {
          commentary
        }
        items {
          id
          item {
            ... on SeasonTicket {
              place {
                id
              }
            }
            ... on Ticket {
              place {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_ORDER_BY_USER_ID = gql`
  query($id: ID!) {
    order {
      getCurrent(userId: $id) {
        id
      }
    }
  }
`;
export const GET_PDF_BY_ORDER_ID = gql`
  query getPDF($orderId: ID!) {
    order {
      getItemsPdf(orderId: $orderId) {
        id
        publicLink
      }
    }
  }
`;
export const GET_WEBTICKET_LINK = gql`
  query getWebTicketLink($itemIds: [ID!]!) {
    order {
      getSeveralShortWebMobileLink(itemIds: $itemIds)
    }
  }
`;