
import Referral from './Referral/Referral';

import { IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import { withProps } from 'recompose';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import { t } from 'i18next';

export class ModuleReferral implements Module {
  private app: IApp;
  private component: any;
  private routeContainer: IRouteContainer;
  private menuContainer: ISidebar;
  private reducers: Array<{ name: string; reducer: any }>;

  constructor(app: IApp, routeContainer: IRouteContainer, menuContainer: ISidebar, authClient: AuthClient) {
    const isReferralAccess =
      window.parametrize('REACT_APP_ENABLE_REFERRAL_PROGRAM') === 'true'
        ? 'MANAGE_REFERRALS'
        : '';
        
    this.component = {
      Referral: withProps({
          authClient: authClient
      })(Referral)
    };
    this.reducers = [];

    this.app = app;
    this.menuContainer = menuContainer;
    this.routeContainer = routeContainer;

    this.reducers.map((reducer: any) => this.app.addReducer(reducer));

    this.routeContainer.addRoute({
      path: '/admin/referral',
      component: this.component.Referral
    });

    const referralLink = new RouteAction({
      title: t('modules.referral_index'),
      icon: 'star',
      name: 'referral',
      route: 'admin/referral',
      order: 11, 
      enabled: true,
      visible: true,
      permission: isReferralAccess,
      localizationKey: 'referral'
    });

    this.menuContainer.addLink(referralLink);
  }

  start() {
  }
}
