import ApolloClient from "apollo-client";
import { pathOr } from "ramda";
import { CHANGE_REFERRAL_RULES } from "../mutation";

export const changeStatusReferral = async (client: ApolloClient<any>, data: Object) => {
  const response = await client.mutate({
    mutation: CHANGE_REFERRAL_RULES,
    variables: { data },
    fetchPolicy: 'no-cache',
  });
  const activateReferral = pathOr({}, ['data', 'referral', 'referralDescriptorMutation', 'update'], response);
  return activateReferral;
}