import React from 'react';
import PromoAddBasic from './PromoAddBasic';
import PromoAddBounds from './PromoAddBounds';
import PromoAddComment from './PromoAddComment';

const AddBody = props => {
  const fieldProps = {
    errors: props.errors,
    touched: props.touched,
    setFieldValue: props.setFieldValue,
    values: props.values
  };
  return (
    <div className="promo-add__body">
      <PromoAddBasic
        {...fieldProps}
        isEditingMode={props.isEditingMode}
        openCodesModal={props.openCodesModal}
        defaultPromoId={props.defaultPromo.id}
        client={props.client}
        setCodesToReducer={props.setCodesToReducer}
      />
      <PromoAddBounds
        {...props}
        values={props.values}
        setFieldValue={props.setFieldValue}
        handleBoundPositionsSelect={props.handleBoundPositionsSelect}
        handleAddNewBound={props.handleAddNewBound}
        handleModalClose={props.handleModalClose}
        fieldProps={fieldProps}
      />
      <PromoAddComment {...fieldProps} />
    </div>
  );
};

export default AddBody;
