import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr } from 'ramda';

import {
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import { AutoComplete, Button, Dropdown, Menu, message, Modal, Radio, Select, Switch } from 'antd';
import {
  APPLY_PROMOCODE,
  CANCEL_PROMOCODE,
  CANCEL_RESERVATION,
  CHANGE_ORDER_USER,
  CREATE_ORDER,
  CREATE_SEASON_TICKETS,
  CREATE_TICKETS,
  GET_ORDER_BY_ID,
  GET_ORDER_BY_USER_ID,
  GET_SEASON_TICKETS_PDF,
  GET_TICKETS_PDF,
  PARTIAL_REFUND_ORDER,
  SEARCH_USERS,
  SEND_ORDER_SMS,
  SEND_ORDER_TO_EMAIL,
  SPLIT_BOOKING,
  UPDATE_ORDER,
} from '../../graphql';
import newUser from '../../img/newUser.svg';
import NewUserModal from './NewUserModal';
import debounce from 'lodash.debounce';
import MapSideMenuItemComponent from './MapSideMenuItem/MapSideMenuItemComponent';
import { useTranslation } from 'react-i18next';
import {
  handleDeReserving,
  handlePrinting,
  handleTextSending,
  handleRefund,
  parsePlacesToOrderData,
  parseUser,
  getUserFromPlaceObj
} from './utils';
import checkTypeName, { RemoteTypeNames } from '../../utils/checkTypeName';
import { getAvailableActions, PlaceActions, SideMenuStep } from '../../utils/model';
import i18next from 'i18next';

const SelectOption = Select.Option;

const { Option } = AutoComplete;

const MapActionSideMenu = (props) => {
  const {t} = useTranslation();
  const [stateProps, setState] = useState({
    isNewUserModalVisible: false,
    isDataVisible: true,
    paymentMethod: 'CLOUDPAYMENTS',
    ticketsArr: [],
    isPaymentProcessing: false,
    isDropdownVisible: false,
    userList: [],
    menuItemsData: [],
    ignoreAdminBlocking: false,
    sendToPhone: false,
    sendToEmail: true,
    currentEventType: checkTypeName(props.event, RemoteTypeNames.MATCH) ? 'TICKET' : 'SEASON_TICKET',
  });
  const renderOption = (item) => {
    return (
      <Option key={item.text} text={item.text} value={''} item={item}>
        <div
          className="permissions__right-container__body__autocomplete__option"
          onClick={async () => {
            if (props.step === SideMenuStep.BUYING) {
              await createNewOrder(item);
            } else {
              setReservationUser(item);
            }
          }}
        >
          <div className="permissions__right-container__body__autocomplete__option__name">{item.value}</div>
          <div className="permissions__right-container__body__autocomplete__option__info">
            <div className="permissions__right-container__body__autocomplete__option__item">
              <PhoneOutlined className="permissions__right-container__body__autocomplete__option__item__icon" />
              <div className="permissions__right-container__body__autocomplete__option__item__text">{item.login}</div>
            </div>
            <div className="permissions__right-container__body__autocomplete__option__item">
              <MailOutlined className="permissions__right-container__body__autocomplete__option__item__icon"/>
              <div className="permissions__right-container__body__autocomplete__option__item__text">{item.email}</div>
            </div>
          </div>
        </div>
      </Option>
    );
  };

  const renderMenuItems = (menuItemsData) => {
    if (menuItemsData && menuItemsData.length) {
      return menuItemsData.map((item) => (
        <Menu.Item
          key={`MapActionSideMenu-action-${item.actionText}`}
          disabled={item.disabled}
          onClick={item.actionHandler}
        >
          {item.actionText}
        </Menu.Item>
      ))
    } else {
      return (
        <Menu.Item
          disabled
        >
          {t('modules.map_action_side_menu.menu_item.empty')}
        </Menu.Item>
      )
    }

  }

  const updateStateProps = (newStateProps) => setState((prevState) => {
    return {
      ...prevState,
      isDropdownVisible: false,
      ...newStateProps,
    }
  })

  const eventTypesNotEqual = () => {
    const types = props.selectedPlaces.map(place => pathOr('', ['place', 'options', 'orderType'], place));
    return types.some(type => type !== stateProps.currentEventType); // All selected places should have equal order type
  }

  const ordersNotEqual = () => {
    const orderIds = props.selectedPlaces.map(place => pathOr('', ['place', 'options', 'orderId'], place));
    return orderIds.some(orderId => orderId !== orderIds[0]); // All selected places should be in one order
  }

  const allPlacesFromSingleOrder = () => {
    const selectedOrdersData = parsePlacesToOrderData(props.selectedPlaces);
    return (selectedOrdersData.length === 1) && !!selectedOrdersData[0].id.length;
  }

  const [sharedItems, setSharedItems] = useState([])

  const entireOrderSelected = async (orderId) => {
    const selectedOrdersData = parsePlacesToOrderData(props.selectedPlaces);
    if (allPlacesFromSingleOrder()) {
      const {
        id: selectedOrderId,
        itemIds: selectedOrderItemIds
      } = selectedOrdersData[0];
      const orderRes = await getOrderById(orderId || selectedOrderId);
      const orderItemIds = pathOr([], ['items'], orderRes)
      .map(item => pathOr('', ['id'], item));
      
      setSharedItems(orderItemIds)
      return orderItemIds.every(id => selectedOrderItemIds.includes(id));
    } else {
      message.error(t('modules.map_action_side_menu.message_error.incorrect_order_action'));
      console.error(t('modules.map_action_side_menu.message_error.incorrect_order_action'));
      return false;
    }
  }

  const setUser = (user) => {
    setState({
      ...stateProps,
      selectedUser: user,
      userIsSelected: true,
      isNewUserModalVisible: false,
    });
  };

  const setReservationUser = (user) => {
    setState({
      ...stateProps,
      selectedUser: user,
      userIsSelected: true,
    });
  };

  const handleUserSearch = async (value) => {
    const res = await props.client.query({
      query: SEARCH_USERS,
      variables: {
        quickSearch: value,
      },
    });
    const list = pathOr([], ['data', 'users', 'getListAdvanced', 'list'], res);
    const mappedList = list.map((person) => parseUser(person, i18next.language));
    setState({
      ...stateProps,
      userList: mappedList,
    });
  };

  const getOrderByUserId = async (userId) => {
    try {
      const res = await props.client.query({
        query: GET_ORDER_BY_USER_ID,
        variables: {
          id: userId,
        },
        fetchPolicy: 'no-cache',
      });
      return pathOr('', ['data', 'order', 'getCurrent'], res);
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_getting_err'));
      console.error(e);
    }
  }

  const getOrderById = async (orderId) => {
    try {
      const res = await props.client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: orderId,
        },
        fetchPolicy: 'no-cache',
      });
      const orderItemsId = pathOr('', ['data', 'order', 'getById', 'id'], res);
      const itemIds = pathOr('', ['data', 'order', 'getById', 'items'], res);
      if (props.step === SideMenuStep.BUYING) props.handleOrderData(orderItemsId, itemIds);
      return pathOr('', ['data', 'order', 'getById'], res);
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_getting_err'));
      console.error(e);
    }
  }

  const createTicket = async (orderData) => {
    try {
      return await props.client.query({
        query: props.isMatch ? CREATE_TICKETS : CREATE_SEASON_TICKETS,
        variables: orderData,
        fetchPolicy: 'no-cache',
      });
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_creation_err'));
      console.error(e);
    }
  }

  const parsePropsToOrderData = (orderId, userId) => {
    return props.isMatch
      ? {
        data: {
          eventId: props.event.id,
          placeIds: props.selectedPlaces.map((item) => {
            return item.id;
          }),
          tag: 'ONLINE',
          orderId,
          userId,
        },
        reserveUntil: stateProps.reserveUntil ? stateProps.reserveUntil : null,
        ignoreAdminBlocking: stateProps.ignoreAdminBlocking,
      }
      : {
        data: {
          seasonTicketDescriptorId: props.event.id,
          placeIds: props.selectedPlaces.map((item) => {
            return item.id;
          }),
          orderId,
          userId,
        },
        reserveUntil: stateProps.reserveUntil ? stateProps.reserveUntil : null,
        ignoreAdminBlocking: stateProps.ignoreAdminBlocking,
      };
  }

  const updateOrder = async (orderId, newData) => {
    try {
      return await props.client.query({
        query: UPDATE_ORDER,
        fetchPolicy: 'no-cache',
        variables: {
          orderId: orderId,
          data: newData,
        },
      });
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_updating_err'));
      console.error(e);
    }
  };

  const changeOrderUser = async (orderId, newUserId) => {
    try {
      return await props.client.query({
        query: CHANGE_ORDER_USER,
        fetchPolicy: 'no-cache',
        variables: {
          orderId,
          newUserId
        },
      });
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_user_updating_err'));
      console.error(e);
    }
  }
  const changeSplitPlace = async (orderId, newUserId) => {
    const orderItemIds = props.selectedPlaces.map(item => item.place.options.orderItemId);
    try {
      return await props.client.query({
        query: SPLIT_BOOKING,
        fetchPolicy: 'no-cache',
        variables: {
          orderId,
          newUserId,
          orderItemIds: orderItemIds
        },
      });
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_user_updating_err'));
      console.error(e);
    }
  }

  const createOrder = async (userId) => {
    try {
      return await props.client.query({
        query: CREATE_ORDER,
        variables: {
          data: {
            userId: userId,
            commentary: stateProps.commentary ? stateProps.commentary : null,
          },
        },
        fetchPolicy: 'no-cache',
      })
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.order_creation_err'));
      console.error(e);
    }
  }

  const bookOrder = async (user, orderId) => {
    let orderIdDefined;
    if (!orderId) {
      const fallbackRes = await getOrderByUserId(user.userId);
      orderIdDefined = pathOr('', ['id'], fallbackRes);
    } else {
      orderIdDefined = orderId;
    }
    const orderData = parsePropsToOrderData(orderIdDefined, user.userId);
    await createTicket(orderData);
    const orderRes = await getOrderById(orderIdDefined);
    const orderCommentary = pathOr('', ['additionalData', 'commentary'], orderRes);
    const orderPrice = pathOr('', ['price'], orderRes);
    await updateOrder(
      orderIdDefined,
      {
        commentary: [`${orderCommentary}`, stateProps.commentary].filter((item) => item).join('; ')
      },
    );

    if (stateProps.reserveUntil) {
      message.success(t('modules.map_action_side_menu.message_success.order_booked'));
      props.setLoading();
      props.setStep(SideMenuStep.DEFAULT);
    }

    setState({
      ...stateProps,
      price: Math.round(parseInt(orderPrice)),
      orderId,
      selectedUser: user,
      userIsSelected: true,
    });
  };

  const changeReservationData = async (user) => {
    const orderId = stateProps.orderId
    const newUserId = user.userId;
    const newCommentary = stateProps.commentary || null;

    await updateOrder(orderId, {commentary: newCommentary});
    (props.selectedPlaces.length !== sharedItems.length)
      ? await changeSplitPlace(orderId, newUserId)
      : await changeOrderUser(orderId, newUserId);

    message.success(t('modules.map_action_side_menu.message_success.order_reservation_changed'));
    props.setLoading();
    props.setStep(SideMenuStep.DEFAULT);
  }


  const createNewOrder = async (user) => {
    const newOrderRes = await createOrder(user.userId);
    const newOrderId = pathOr('', ['data', 'order', 'create', 'id'], newOrderRes);
    await bookOrder(user, newOrderId);
  };

  const updateSelectedPlaces = async (nextStep) => {
    const orderData = parsePlacesToOrderData(props.selectedPlaces);
    const orderRes = await getOrderById(orderData[0].id);
  
    let placeIds;
    (sharedItems.length > 0) 
      ? placeIds = pathOr([], ['items'], sharedItems).map(item => pathOr('', ['item', 'place', 'id'], item))
      : placeIds = pathOr([], ['items'], orderRes).map(item => pathOr('', ['item', 'place', 'id'], item));
   
    if (placeIds.length > 0) props.setSelectedPlaces(placeIds, nextStep || SideMenuStep.REDEEMING);
  }
  const selectAllPlaces = async () => {
    const orderData = parsePlacesToOrderData(props.selectedPlaces);

    if (orderData.length > 0) {
      const orderRes = await getOrderById(orderData[0].id);
      const allPlaceIds = pathOr([], ['items'], orderRes).map((item) => pathOr('', ['item', 'place', 'id'], item));
      props.setSelectedPlaces(allPlaceIds, SideMenuStep.REDEEMING);
    }
  };
  const goToBuying = async (orderId) => {
    const person = props.selectedPlaces
    .map((place) => getUserFromPlaceObj(place))[0];

    const orderRes = await getOrderById(orderId);
    const orderCommentary = pathOr('', ['additionalData', 'commentary'], orderRes);
    const orderPrice = pathOr('', ['price'], orderRes);

    const user = parseUser(person, i18next.language);
    setState({
      ...stateProps,
      commentary: orderCommentary,
      price: Math.round(parseInt(orderPrice)),
      orderId,
      selectedUser: user,
      userIsSelected: true,
      isNewUserModalVisible: false,
      isDropdownVisible: false,
      isDataVisible: true,
      ignoreAdminBlocking: false,
    });
    props.setStep(SideMenuStep.REDEEMING);
  };

  const goToReservationChanging = async (orderId) => {
    const person = props.selectedPlaces
    .map((place) => getUserFromPlaceObj(place))[0];

    const orderRes = await getOrderById(orderId);
    const orderCommentary = pathOr('', ['additionalData', 'commentary'], orderRes);
    const orderPrice = pathOr('', ['price'], orderRes);
    const user = parseUser(person, i18next.language);
    setState({
      ...stateProps,
      commentary: orderCommentary,
      price: Math.round(parseInt(orderPrice)),
      orderId,
      selectedUser: user,
      userIsSelected: true,
      isNewUserModalVisible: false,
      isDropdownVisible: false,
      ignoreAdminBlocking: false,
    });
  }

  const processOrderAction = async (nextStep, action, resetAction) => {
    const isOrderSelected = await entireOrderSelected();
    const isNotDefaultStep = nextStep !== SideMenuStep.DEFAULT;
    const isContinueStep = isNotDefaultStep && (nextStep === props.step);
    const defaultStateReset = () => {
      props.setStep(SideMenuStep.DEFAULT);
    };

    const reset = () => resetAction ? resetAction() : defaultStateReset();

    if (isOrderSelected) {
      const orderId = parsePlacesToOrderData(props.selectedPlaces)[0].id;
      if (isContinueStep) {
        action(orderId);
      } else {
        props.setStep(nextStep);
        action(orderId);
      }
    } else if (allPlacesFromSingleOrder()) {
      props.setStep(nextStep);
      await updateSelectedPlaces(nextStep);
    } else {
      reset();
    }
  }

  //  At the moment, there is no mechanism on the backend to modify the number of seats in an order
  //  without resorting to cancellation requests for specific seats and then obtaining an updated order.
  //  Consequently, when selecting certain seats, the order price remains the same for all seats.
  //  Once this implementation becomes available, we can consider enhancing this functionality here.
  const redeemOrderPartially = async () => {
    if (allPlacesFromSingleOrder()) {
      props.setStep(SideMenuStep.PARTIAL_REDEEMING);
      const orderId = parsePlacesToOrderData(props.selectedPlaces)[0].id;
      // TODO: Mutate the order before continue redeeming
      await goToBuying(orderId);
    } else {
      props.setStep(SideMenuStep.DEFAULT);
      message.error(t('modules.map_action_side_menu.message_error.incorrect_order_action'));
      console.error(t('modules.map_action_side_menu.message_error.incorrect_order_action'));
    }
  }

  const printPDF = async (ticketIds) => {
    try {
      message.loading(t('modules.map_action_side_menu.message.loading_pdf'));
      const res = await props.client.query({
        query: props.isMatch ? GET_TICKETS_PDF : GET_SEASON_TICKETS_PDF,
        variables: {
          ids: ticketIds,
        },
      });
      const url = pathOr(
        undefined,
        [
          'data',
          checkTypeName(props.event, RemoteTypeNames.MATCH) ? 'ticket' : 'seasonTicket',
          'getPdfByIds',
          'publicLink'
        ],
        res
      );
      window.open(url, '_blank');
    } catch (e) {
      console.error(e);
      message.error(t('modules.map_action_side_menu.message_error.ticket_load_err'));
    }
  };

  const sendOrderSMS = async (orderId, phone) => {
    try {
      await props.client.query({
        query: SEND_ORDER_SMS,
        variables: {
          orderId,
          phone,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.map_action_side_menu.message_success.sms_sent'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.map_action_side_menu.message_error.sms_sending_err'));
    }
  };

  const sendOrderEmail = async (orderId, email) => {
    try {
      await props.client.query({
        query: SEND_ORDER_TO_EMAIL,
        variables: {
          orderId,
          overrideEmail: email,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.map_action_side_menu.message_success.email_sent'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.map_action_side_menu.message_error.send_letter_err'));
    }
  };

  const onModalCancel = () => {
    setState({
      ...stateProps,
      isNewUserModalVisible: false,
    });
  };
  const applyPromocode = async () => {
    try {
      const res = await props.client.query({
        query: APPLY_PROMOCODE,
        variables: {
          orderId: stateProps.orderId,
          promocode: stateProps.promocode,
        },
        fetchPolicy: 'no-cache',
      });
      const priceWithDiscount = pathOr('', ['data', 'order', 'applyPromocode', 'priceWithDiscount'], res);

      setState({
        ...stateProps,
        promocodeApplied: true,
        priceWithDiscount: Math.round(parseInt(priceWithDiscount)),
        isPromocodeLoading: false,
      });
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.promocode_usage_err'));
      setState({
        ...stateProps,
        isPromocodeLoading: false,
      });
      console.error(e);
    }
  };
  const cancelPromocode = async () => {
    try {
      await props.client.query({
        query: CANCEL_PROMOCODE,
        variables: {
          orderId: stateProps.orderId,
        },
        fetchPolicy: 'no-cache',
      });
      setState({
        ...stateProps,
        promocodeApplied: false,
        priceWithDiscount: '',
        isPromocodeLoading: false,
      });
    } catch (e) {
      message.error(t('modules.map_action_side_menu.message_error.promocode_cancellation_err'));
      console.error(e);
    }
  };

  const wordEnd = (number, word, end1, end2, end3) => {
    if (Number(String(number).length) > 2) number = Number(String(number).substr(-2));
    if (number === 11 || number === 12 || number === 13 || number === 14) {
      return word + end3;
    } else {
      switch (Number(String(number).substr(-1))) {
        case 1:
          return word + end1;
        case 2:
        case 3:
        case 4:
          return word + end2;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 0:
          return word + end3;
        default:
          return word;
      }
    }
  };

  const initPayment = async () => {
    const script = document.createElement('script');
    const credentials = props.authClient.credentials;
    const token = credentials ? credentials.idToken : '';

    script.src = window.parametrize('REACT_APP_PAYMENT_LIB_LINK');
    script.async = true;

    document.body.appendChild(script);
    script.onload = function () {
      // eslint-disable-next-line no-unused-vars
      /*global PaymentsClient, a*/
      /*eslint no-undef: "error"*/
      var payments = new PaymentsClient(null, token, window.parametrize('REACT_APP_GRAPHQL_ENDPOINT'), '#000', false);
      /**
       * Main method
       * @param {string} orderId
       * @param {string} adapterName
       * @param {function | null} onSuccess
       * @param {function | null} onError
       * @param {string | null} overrideEmail
       * @param {string | null} overridePhone
       * @param {function | null} onCancel
       * @param {string | null} loyaltyAmount
       */
      payments.pay(
        stateProps.orderId,
        stateProps.paymentMethod,
        () => {
          //in case of success
          message.success(t('modules.map_action_side_menu.message_success.payment_done'));
          if (stateProps.commentary) {
            updateOrder(stateProps.orderId, stateProps.commentary);
          }
          setState({
            ...stateProps,
            isPaymentProcessing: false,
          });
          if (stateProps.sendToPhone) {
            sendOrderSMS(stateProps.orderId, stateProps.selectedUser.login);
          }
          if (stateProps.sendToEmail) {
            sendOrderEmail(stateProps.orderId, stateProps.selectedUser.email);
          }
          props.setLoading();
          props.setStep(SideMenuStep.DEFAULT);
        },
        (data) => {
          //in case of error
          console.error(data);
          setState({
            ...stateProps,
            isPaymentProcessing: false,
          });
          message.error(t('modules.map_action_side_menu.message_error.payment_err'));
        }
      );
    };
  };

  const initRefund = async (orderId, orderItemIds) => {
    try {
      await props.client.query({
        query: PARTIAL_REFUND_ORDER,
        variables: {
          orderId,
          orderItemIds,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.map_action_side_menu.message_success.ticket_refunded'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.map_action_side_menu.message_error.ticket_refund_err'));
    }
  };

  const cancelReservation = async (orderId, orderItemIds) => {
    try {
      await props.client.query({
        query: CANCEL_RESERVATION,
        variables: {
          orderId,
          orderItemIds,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.map_action_side_menu.message_success.order_reservation_cancelled'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.map_action_side_menu.message_error.reservation_cancelling_error'));
    }
  };

  const menuItemsConfig = [
    {
      action: PlaceActions.PRINT,
      disabled: eventTypesNotEqual(),
      actionText: t('modules.map_action_side_menu.menu_item.print'),
      actionHandler: async () => {
        updateStateProps();
        await handlePrinting(props.selectedPlaces, printPDF)
      },
    },
    {
      action: PlaceActions.BUY,
      actionText: t('modules.map_action_side_menu.menu_item.buy'),
      actionHandler: () => {
        updateStateProps({ignoreAdminBlocking: false});
        props.setStep(SideMenuStep.BUYING);
      }
    },
    {
      action: PlaceActions.REDEEM_ORDER,
      actionText: t('modules.map_action_side_menu.menu_item.order_redeeming'),
      disabled: eventTypesNotEqual() || ordersNotEqual() || !allPlacesFromSingleOrder(),
      actionHandler: async () => await processOrderAction(SideMenuStep.REDEEMING, goToBuying),
    },
    {
      action: PlaceActions.REDEEM_ORDER_PARTIALLY,
      actionText: t('modules.map_action_side_menu.menu_item.partial_order_redeeming'),
      // TODO: Uncomment when it becomes possible to modify the number of seats in the order.
      // disabled={eventTypesNotEqual() || ordersNotEqual() || !allPlacesFromSingleOrder()}
      disabled: true,
      actionHandler: async () => await redeemOrderPartially(),
    },
    {
      action: PlaceActions.CHANGE_RESERVATION,
      actionText: t('modules.map_action_side_menu.menu_item.change_reservation'),
      disabled: eventTypesNotEqual() || ordersNotEqual() || !allPlacesFromSingleOrder(),
      actionHandler: async () => await processOrderAction(SideMenuStep.RESERVATION_CHANGING, goToReservationChanging),
    },
    {
      action: PlaceActions.CANCEL_RESERVATION,
      actionText: t('modules.map_action_side_menu.menu_item.cancel_reservation'),
      disabled: eventTypesNotEqual(),
      actionHandler: () => updateStateProps({isAcceptRefundModalVisible: true}),
    },
    {
      action: PlaceActions.SEND_BY_EMAIL,
      actionText: t('modules.map_action_side_menu.menu_item.email'),
      disabled: eventTypesNotEqual(),
      actionHandler: async () => {
        updateStateProps();
        await handleTextSending(props.selectedPlaces, sendOrderEmail, 'email');
      },
    },
    {
      action: PlaceActions.SEND_BY_TEXT_MESSAGE,
      actionText: t('modules.map_action_side_menu.menu_item.sms'),
      disabled: eventTypesNotEqual(),
      actionHandler: async () => {
        updateStateProps();
        await handleTextSending(props.selectedPlaces, sendOrderSMS, 'phone');
      },
    },
    {
      action: PlaceActions.BOOK,
      actionText: t('modules.map_action_side_menu.menu_item.book'),
      actionHandler: () => {
        updateStateProps({ignoreAdminBlocking: false});
        props.setStep(SideMenuStep.RESERVING);
      },
    },
    {
      action: PlaceActions.REFUND,
      actionText: t('modules.map_action_side_menu.menu_item.refund'),
      disabled: eventTypesNotEqual(),
      actionHandler: () => updateStateProps({isAcceptRefundModalVisible: true}),
    },
  ];

  useEffect(() => {
    async function continueOrderProcessing(step) {
      switch (step) {
        case SideMenuStep.REDEEMING:
          await processOrderAction(SideMenuStep.REDEEMING, goToBuying);
          break;
        case SideMenuStep.RESERVATION_CHANGING:
          await processOrderAction(SideMenuStep.RESERVATION_CHANGING, goToReservationChanging);
          break;
        default:
          break;
      }
    }

    async function init() {
      const dropDownMode = ['ACTIVE', 'BLOCKED', 'SOLD', 'RESERVED'].map((item) => {
        const newArr = props.selectedPlaces.filter((place) => {
          const status = pathOr('', ['place', 'options', 'status'], place);
          return status === item;
        });
        if (newArr.length === props.selectedPlaces.length) {
          return item;
        }
        return '';
      });

      const definedDropDownMode = dropDownMode.filter(Boolean)[0];
      const menuItemsData = getAvailableActions(definedDropDownMode, menuItemsConfig);

      setState({
        ...stateProps,
        dropDownMode: definedDropDownMode,
        menuItemsData,
      });

      if (props.selectedPlaces.length) {
        continueOrderProcessing(props.step);
      } else {
        props.setStep(SideMenuStep.DEFAULT);
      }
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPlaces]);


  useEffect(() => {
    // Reset step when unmount
    return () => {
      ![SideMenuStep.RESERVATION_CHANGING, SideMenuStep.REDEEMING, SideMenuStep.DEFAULT].includes(props.step) && props.setStep(SideMenuStep.DEFAULT);
    }
  }, [])

  const renderHeaderActionText = () => {
    const headers = [
      {step: SideMenuStep.REDEEMING, header: t('modules.map_action_side_menu.header.redeem')},
      {step: SideMenuStep.PARTIAL_REDEEMING, header: t('modules.map_action_side_menu.header.redeem')},
      {step: SideMenuStep.BUYING, header: t('modules.map_action_side_menu.header.buy')},
      {step: SideMenuStep.RESERVING, header: t('modules.map_action_side_menu.header.book')},
      {step: SideMenuStep.RESERVATION_CHANGING, header: t('modules.map_action_side_menu.header.change_reservation')},
      {step: SideMenuStep.DEFAULT, header: t('modules.map_action_side_menu.header.default')},
    ]

    return headers.find(el => el.step === props.step).header || t('modules.map_action_side_menu.header.default');
  }

  return (
    <>
      <div className="sales__tickets__side-menu__container">
        <div className="sales__tickets__side-menu__header">
          <CloseOutlined
            onClick={() => props.onClose()}
            className="sales__tickets__side-menu__header__icon"
          />
          <div className="sales__tickets__side-menu__header__action">
            {renderHeaderActionText()}
          </div>
          {([SideMenuStep.BUYING, SideMenuStep.RESERVING, SideMenuStep.RESERVATION_CHANGING].includes(props.step)) && (
            <img
              onClick={() => {
                setState({
                  ...stateProps,
                  isNewUserModalVisible: true,
                });
              }}
              className="sales__tickets__side-menu__header__add-user"
              src={newUser}
              alt={t('modules.map_action_side_menu.img')}
            />
          )}
        </div>
        {[SideMenuStep.RESERVING, SideMenuStep.RESERVATION_CHANGING].includes(props.step) && (
          <>
            <div className="sales__tickets__side-menu__switcher-container">
              <div className="permissions__right-container__body__switcher">
                <div
                  className={
                    stateProps.isDataVisible
                      ? 'permissions__right-container__body__switcher__item__active'
                      : 'permissions__right-container__body__switcher__item'
                  }
                  onClick={() => {
                    setState({
                      ...stateProps,
                      isDataVisible: true,
                    });
                  }}
                  style={{
                    borderRadius: '4px 0 0 4px',
                  }}
                >
                  {t('modules.map_action_side_menu.div.data')}
                </div>
                <div
                  className={
                    stateProps.isDataVisible
                      ? 'permissions__right-container__body__switcher__item'
                      : 'permissions__right-container__body__switcher__item__active'
                  }
                  style={{
                    borderRadius: '0 4px 4px 0',
                  }}
                  onClick={() => {
                    setState({
                      ...stateProps,
                      isDataVisible: false,
                    });
                  }}
                >
                  {t('modules.map_action_side_menu.div.tickets')}
                </div>
              </div>
            </div>
            {!stateProps.isDataVisible && (
              <div className="sales__tickets__side-menu__ticket__container">
                {props.selectedPlaces.map((item) => (
                  <div className="sales__tickets__side-menu__ticket">
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.sector')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">{item.sector}</div>
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.row')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">{item.row}</div>
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.seat')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">{item.number}</div>
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.price')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">
                        {Math.round(parseInt(item.price)) + ` ${t('currency')}`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {stateProps.isDataVisible && (
              <div className="sales__tickets__side-menu__body">
                {stateProps.userIsSelected && (
                  <div className="pd">
                    <div className="sales__tickets__side-menu__name">{stateProps.selectedUser.userName}</div>
                    <div className="sales__tickets__side-menu__item">
                      <PhoneOutlined className="sales__tickets__side-menu__contacts-icon"/>
                      <div className="sales__tickets__side-menu__contacts-item">{stateProps.selectedUser.login}</div>
                    </div>
                    <div className="sales__tickets__side-menu__item">
                      <MailOutlined className="sales__tickets__side-menu__contacts-icon"/>
                      <div className="sales__tickets__side-menu__contacts-item">{stateProps.selectedUser.email}</div>
                    </div>
                  </div>
                )}
                {(!stateProps.userIsSelected || (props.step === SideMenuStep.RESERVATION_CHANGING)) && (
                  <>
                    <div className="pd sales__tickets__side-menu__subtitle">
                      {
                        props.step === SideMenuStep.RESERVATION_CHANGING
                          ? t('modules.map_action_side_menu.div.new_user')
                          : t('modules.map_action_side_menu.div.user')
                      }
                    </div>
                    <AutoComplete
                      className="sales__tickets__side-menu__autocomplete"
                      placeholder={t('modules.map_action_side_menu.autocomplete_placeholder')}
                      dataSource={[...stateProps.userList.map(renderOption)]}
                      optionLabelProp="value"
                      allowClear
                      defaultActiveFirstOption={false}
                      onSearch={debounce(handleUserSearch, 500)}
                      filterOption={() => {
                        return true;
                      }}
                    />
                    {
                      props.step === SideMenuStep.RESERVATION_CHANGING
                        && <Button onClick={selectAllPlaces}>
                        {t('modules.map_action_side_menu.selected_all_place')}
                      </Button>
                    }
                  </>
                )}
                {props.step === SideMenuStep.RESERVING && (
                  <>
                    <div
                      className="sales__tickets__side-menu__subtitle pd">{t('modules.map_action_side_menu.div.booking_limit')}</div>
                    <Select
                      placeholder={t('modules.map_action_side_menu.select.select_timing')}
                      className="sales__tickets__side-menu__select"
                      defaultValue={stateProps.reserveUntilValue}
                      onChange={(ev) => {
                        if (ev === 1) {
                          const date = new Date().getTime();
                          const newDate = date + 900000;
                          setState({
                            ...stateProps,
                            reserveUntil: new Date(newDate).toISOString(),
                            reserveUntilValue: ev,
                          });
                        }
                        if (ev === 2) {
                          const date = new Date();
                          date.setDate(date.getDate() + 1);
                          setState({
                            ...stateProps,
                            reserveUntil: new Date(date).toISOString(),
                            reserveUntilValue: ev,
                          });
                        }
                        if (ev === 3) {
                          const date = new Date();
                          date.setDate(date.getDate() + 7);
                          setState({
                            ...stateProps,
                            reserveUntil: new Date(date).toISOString(),
                            reserveUntilValue: ev,
                          });
                        }
                        if (ev === 4) {
                          const date = new Date();
                          date.setMonth(date.getMonth() + 3);
                          setState({
                            ...stateProps,
                            reserveUntil: new Date(date).toISOString(),
                            reserveUntilValue: ev,
                          });
                        }
                      }}
                    >
                      <SelectOption value={1}>{t('modules.map_action_side_menu.select.15_min')}</SelectOption>
                      <SelectOption value={2}>{t('modules.map_action_side_menu.select.24_hours')}</SelectOption>
                      <SelectOption value={3}>{t('modules.map_action_side_menu.select.7_days')}</SelectOption>
                      <SelectOption value={4}>{t('modules.map_action_side_menu.select.3_months')}</SelectOption>
                    </Select>
                  </>
                )}
                <div
                  className="sales__tickets__side-menu__subtitle pd">{t('modules.map_action_side_menu.div.comment')}</div>
                <input
                  onChange={(ev) => {
                    setState({
                      ...stateProps,
                      commentary: ev.target.value,
                    });
                  }}
                  placeholder={t('modules.map_action_side_menu.input.comment_text')}
                  value={stateProps.commentary}
                  className="sales__tickets__side-menu__comment-input"
                />
                <div className="sales__tickets__side-menu__footer pd">
                  <div className="sales__tickets__side-menu__ticket-count">
                    {props.selectedPlaces.length}{' '}
                    {wordEnd(
                      props.selectedPlaces.length,
                      t('modules.map_action_side_menu.wordend.word'),
                      '',
                      `${t('modules.map_action_side_menu.wordend.singular')} `,
                      t('modules.map_action_side_menu.wordend.plural')
                    )}
                  </div>
                  <div className="sales__tickets__side-menu__price">
                    <span className="sales__tickets__side-menu__price">
                      {Math.round(
                        parseInt(
                          props.selectedPlaces.reduce((a, b) => {
                            return {
                              price: Math.round(parseInt(a.price)) + Math.round(parseInt(b.price)),
                            };
                          }).price
                        )
                      )}
                    </span>{' '}
                    {t('currency')}
                  </div>
                </div>
                <Button
                  id="MapActionSideMenuBookButton"
                  className="sales__tickets__side-menu__accept-button"
                  onClick={async () => {
                    if (stateProps.userIsSelected) {
                      if (props.step === SideMenuStep.RESERVING && stateProps.reserveUntil) {
                        setState({
                          ...stateProps,
                          isPaymentProcessing: true,
                        });
                        await bookOrder(stateProps.selectedUser);
                      } else if (props.step === SideMenuStep.RESERVATION_CHANGING) {
                        setState({
                          ...stateProps,
                          isPaymentProcessing: true,
                        });
                        await changeReservationData(stateProps.selectedUser)
                      } else if (!stateProps.reserveUntil) {
                        message.error(t('modules.map_action_side_menu.message_error.select_booking_limit'));
                      }
                    } else if (!stateProps.userIsSelected) {
                      message.error(t('modules.map_action_side_menu.message_error.select_booking_user'));
                    }
                  }}
                >
                  {stateProps.isPaymentProcessing
                    ? (<LoadingOutlined/>)
                    : props.step === SideMenuStep.RESERVING
                      ? t('modules.map_action_side_menu.button.book')
                      : t('modules.map_action_side_menu.button.change')
                  }
                </Button>
              </div>
            )}
          </>
        )}
        {[SideMenuStep.BUYING, SideMenuStep.REDEEMING].includes(props.step) && (
          <>
            <div className="sales__tickets__side-menu__switcher-container">
              <div className="sales__tickets__side-menu__switcher">
                <div
                  className={
                    stateProps.isDataVisible
                      ? 'sales__tickets__side-menu__switcher__item__active'
                      : 'sales__tickets__side-menu__switcher__item'
                  }
                  onClick={() => {
                    setState({
                      ...stateProps,
                      isDataVisible: true,
                    });
                  }}
                  style={{
                    borderRadius: '4px 0 0 4px',
                  }}
                >
                  {t('modules.map_action_side_menu.div.data')}
                </div>
                <div
                  className={
                    stateProps.isDataVisible
                      ? 'permissions__right-container__body__switcher__item'
                      : 'permissions__right-container__body__switcher__item__active'
                  }
                  onClick={() => {
                    setState({
                      ...stateProps,
                      isDataVisible: false,
                    });
                  }}
                  style={{
                    borderRadius: '0 4px 4px 0',
                  }}
                >
                  {t('modules.map_action_side_menu.div.tickets')}
                </div>
              </div>
            </div>

            {stateProps.isDataVisible && (
              <>
                {!stateProps.userIsSelected && (
                  <>
                    <AutoComplete
                      className="sales__tickets__side-menu__autocomplete"
                      placeholder={t('modules.map_action_side_menu.autocomplete_placeholder')}
                      dataSource={[...stateProps.userList.map(renderOption)]}
                      optionLabelProp="value"
                      allowClear
                      defaultActiveFirstOption={false}
                      onSearch={debounce(handleUserSearch, 500)}
                      filterOption={() => {
                        return true;
                      }}
                    />

                    <div className="sales__tickets__side-menu__autocomplete__tip">
                      {t('modules.map_action_side_menu.div.select_user')}
                    </div>
                  </>
                )}

                {stateProps.userIsSelected && (
                  <div
                    style={{
                      padding: '16px',
                    }}
                  >
                    <div className="sales__tickets__side-menu__name">{stateProps.selectedUser.userName}</div>
                    <div className="sales__tickets__side-menu__item">
                      <PhoneOutlined className="sales__tickets__side-menu__contacts-icon" />
                      <div className="sales__tickets__side-menu__contacts-item">{stateProps.selectedUser.login}</div>
                    </div>
                    <div className="sales__tickets__side-menu__item">
                      <MailOutlined className="sales__tickets__side-menu__contacts-icon" />
                      <div className="sales__tickets__side-menu__contacts-item">{stateProps.selectedUser.email}</div>
                    </div>
                    <div className="sales__tickets__side-menu__subtitle">
                      {t('modules.map_action_side_menu.div.comment')}
                    </div>
                    <input
                      onChange={(ev) => {
                        setState({
                          ...stateProps,
                          commentary: ev.target.value,
                        });
                      }}
                      placeholder={t('modules.map_action_side_menu.input.comment_text')}
                      value={stateProps.commentary}
                      className="sales__tickets__side-menu__comment-input"
                    />
                    <div className="sales__tickets__side-menu__subtitle">
                      {t('modules.map_action_side_menu.div.pay_method')}
                    </div>
                    <Radio.Group
                      className="sales__tickets__side-menu__radio-group"
                      value={stateProps.paymentMethod}
                      onChange={(ev) => {
                        setState({
                          ...stateProps,
                          paymentMethod: ev.target.value,
                        });
                      }}
                    >
                      <Radio value={process.env.REACT_APP_BANK_TRANSFER || 'BANK_TRANSFER'}>
                        {t('modules.map_action_side_menu.radio.bank_transfer')}</Radio>
                      <Radio value={'CLOUDPAYMENTS'}>{t('modules.map_action_side_menu.radio.card')}</Radio>
                      <Radio value={'OTHER'}>{t('modules.map_action_side_menu.radio.other')}</Radio>
                    </Radio.Group>
                    <div className="sales__tickets__side-menu__subtitle">
                      {t('modules.map_action_side_menu.div.where_send')}
                    </div>
                    <div className="sales__tickets__side-menu__switch">
                      <div
                        className={
                          stateProps.sendToPhone
                            ? 'sales__tickets__side-menu__switch__text'
                            : 'sales__tickets__side-menu__switch__text sales__tickets__side-menu__disabled'
                        }
                      >
                        {t('modules.map_action_side_menu.div.via_sms')} {stateProps.selectedUser.login}
                      </div>
                      <Switch
                        size="small"
                        className="sales__tickets__side-menu__switch__item"
                        checked={stateProps.sendToPhone}
                        onChange={(ev) => {
                          setState({
                            ...stateProps,
                            sendToPhone: ev,
                          });
                        }}
                      />
                    </div>
                    {stateProps.selectedUser.email ? (
                      <div className="sales__tickets__side-menu__switch">
                        <div
                          className={
                            stateProps.sendToEmail
                              ? 'sales__tickets__side-menu__switch__text'
                              : 'sales__tickets__side-menu__switch__text sales__tickets__side-menu__disabled'
                          }
                        >
                          {t('modules.map_action_side_menu.div.via_email')} {stateProps.selectedUser.email}
                        </div>
                        <Switch
                          checked={stateProps.sendToEmail}
                          size="small"
                          className="sales__tickets__side-menu__switch__item"
                          defaultChecked={stateProps.sendToEmail}
                          onChange={(ev) => {
                            setState({
                              ...stateProps,
                              sendToEmail: ev,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div className="sales__tickets__side-menu__switch__blank"></div>
                    )}
                    <div className="sales__tickets__side-menu__subtitle">
                      {t('modules.map_action_side_menu.div.promocode')}
                    </div>
                    <div className="sales__tickets__side-menu__promo">
                      <input
                        className="sales__tickets__side-menu__promo-input"
                        placeholder={
                          stateProps.promocodeApplied ? stateProps.promocode : t('modules.map_action_side_menu.input.enter_promocode')
                        }
                        onChange={(ev) => {
                          setState({
                            ...stateProps,
                            promocode: ev.target.value,
                          });
                        }}
                      />
                      <Button
                        id="MapActionSideMenuPromocodeAppliedButton"
                        className="sales__tickets__side-menu__promo-accept"
                        onClick={async () => {
                          if (stateProps.promocodeApplied) {
                            setState({
                              ...stateProps,
                              isPromocodeLoading: true,
                            });
                            await cancelPromocode();
                          } else {
                            if (!isEmpty(stateProps.promocode)) {
                              setState({
                                ...stateProps,
                                isPromocodeLoading: true,
                              });
                              await applyPromocode();
                            } else {
                              message.error(t('modules.map_action_side_menu.message_error.enter_promocode'));
                            }
                          }
                        }}
                      >
                        {stateProps.isPromocodeLoading ? (
                          <LoadingOutlined />
                        ) : stateProps.promocodeApplied ? (
                          t('modules.map_action_side_menu.button.revoke')
                        ) : (
                          t('modules.map_action_side_menu.button.apply')
                        )}
                      </Button>
                    </div>
                    <div className="sales__tickets__side-menu__footer">
                      <div className="sales__tickets__side-menu__ticket-count">
                        {props.selectedPlaces.length}{' '}
                        {wordEnd(
                          props.selectedPlaces.length,
                          t('modules.map_action_side_menu.wordend.word'),
                          '',
                          `${t('modules.map_action_side_menu.wordend.singular')} `,
                          t('modules.map_action_side_menu.wordend.plural')
                        )}
                      </div>
                      <div className="sales__tickets__side-menu__price">
                        <span
                          className={
                            stateProps.promocodeApplied
                              ? 'sales__tickets__side-menu__price__promo'
                              : 'sales__tickets__side-menu__price'
                          }
                        >
                          {stateProps.price}
                        </span>{' '}
                        <span className="sales__tickets__side-menu__price__new">{stateProps.priceWithDiscount}</span> P
                      </div>
                    </div>
                    <Button
                      id="MapActionSideMenuBuyButton"
                      className="sales__tickets__side-menu__accept-button"
                      onClick={async () => {
                        setState({
                          ...stateProps,
                          isPaymentProcessing: true,
                        });
                        await initPayment();
                      }}
                    >
                      {stateProps.isPaymentProcessing ? (
                        <LoadingOutlined />
                      ) : (
                        t('modules.map_action_side_menu.button.buy')
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}

            {!stateProps.isDataVisible && (
              <div className="sales__tickets__side-menu__ticket__container">
                {props.selectedPlaces.map((item) => (
                  <div className="sales__tickets__side-menu__ticket">
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.sector')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">{item.sector}</div>
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.row')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">{item.row}</div>
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.seat')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">{item.number}</div>
                    </div>
                    <div className="sales__tickets__side-menu__ticket__item">
                      <div className="sales__tickets__side-menu__ticket__item-title">
                        {t('modules.map_action_side_menu.div.price')}
                      </div>
                      <div className="sales__tickets__side-menu__ticket__item-text">
                        {Math.round(parseInt(item.price)) + ` ${t('currency')}`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {props.step === SideMenuStep.DEFAULT && (
          <>
            {props.status === 'ACTIVE' && (
              <div className="sales__tickets__side-menu__select-action">
                <div className="sales__tickets__side-menu__select-action__text">
                  {t('modules.map_action_side_menu.div.group_action')}
                </div>
                <Dropdown
                  className="sales__tickets__side-menu__dropdown"
                  trigger={['click']}
                  visible={stateProps.isDropdownVisible}
                  overlay={
                    <Menu>
                      {renderMenuItems(stateProps.menuItemsData)}
                    </Menu>
                  }
                  onClick={() => {
                    setState({
                      ...stateProps,
                      isDropdownVisible: true,
                    });
                  }}
                  onVisibleChange={(ev) => {
                    setState({
                      ...stateProps,
                      isDropdownVisible: ev,
                    });
                  }}
                >
                  <div>
                    <div className="sales__tickets__filter-modal__dropdown__item">
                      {t('modules.map_action_side_menu.div.choose_action')}
                    </div>
                    <DownOutlined className="sales__tickets__filter-modal__dropdown__icon" />
                  </div>
                </Dropdown>
              </div>
            )}
            <div className="sales__tickets__side-menu__ticket__container">
              {props.selectedPlaces.map((item) => {
                return (
                  <MapSideMenuItemComponent
                    key={item.id}
                    client={props.client}
                    item={item}
                    isMatch={props.isMatch}
                    filterId={props.event.id}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <NewUserModal
        isNewUserModalVisible={stateProps.isNewUserModalVisible}
        onCancel={() => {
          onModalCancel();
        }}
        client={props.client}
        setUser={async (user) => {
          await setUser(user);
          if (props.step === SideMenuStep.BUYING) {
            await createNewOrder(user);
          }
        }}
      />
      <Modal
        visible={stateProps.isAcceptRefundModalVisible}
        centered
        closable={false}
        onCancel={() => {
          setState({
            ...stateProps,
            isAcceptRefundModalVisible: false,
          });
        }}
        width={415}
        footer={null}
      >
        <>
          <div className="sales__tickets__list-table__accept-modal__text-container">
            <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
            <div className="sales__tickets__list-table__accept-modal__text">
              {stateProps.dropDownMode === 'RESERVED'
                ? t('modules.map_action_side_menu.div.remove_confirm')
                : stateProps.dropDownMode === 'SOLD'
                ? t('modules.map_action_side_menu.div.return_confirm')
                : ''}
            </div>
          </div>
          <div className="sales__tickets__list-table__accept-modal__button-container">
            <Button
              id="MapActionSideMenuCancelButton"
              className="sales__tickets__list-table__accept-modal__decline-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  isAcceptRefundModalVisible: false,
                });
              }}
            >
              {t('modules.map_action_side_menu.button.cancel')}
            </Button>
            <Button
              id="MapActionSideMenuReturnButton"
              className="sales__tickets__list-table__accept-modal__accept-button"
              onClick={async () => {
                if (['SOLD', 'RESERVED'].includes(stateProps.dropDownMode)) {
                  setState({
                    ...stateProps,
                    isRefundProcessing: true,
                  });

                  const resetState = () => {
                    setState({
                      ...stateProps,
                      isRefundProcessing: false,
                      isAcceptRefundModalVisible: false
                    })
                    props.setLoading();
                  }

                  if (stateProps.dropDownMode === 'SOLD') {
                    await handleRefund(props.selectedPlaces, initRefund)
                    .finally(() => resetState());
                  } else {
                    await handleDeReserving(props.selectedPlaces, cancelReservation)
                    .finally(() => resetState());
                  }
                }
              }}
            >
              {stateProps.isRefundProcessing ? (
                <LoadingOutlined/>
              ) : stateProps.dropDownMode === 'RESERVED' ? (
                t('modules.map_action_side_menu.button.return')
              ) : stateProps.dropDownMode === 'SOLD' ? (
                t('modules.map_action_side_menu.button.refund')
              ) : (
                ''
              )}
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default MapActionSideMenu;
