import React, { useEffect, useState } from 'react';
import MapComponent from './MapComponent';
import { compose, isEmpty, pathOr } from 'ramda';
import { LoadingOutlined } from '@ant-design/icons';
import connect from '../../containers/connect';
import {
  CANCEL_RESERVATION,
  GET_MATCH_PLACES,
  GET_SEASON_PLACES,
  GET_SEASON_TICKET_ORDER_ITEMS,
  GET_TICKET_ORDER_ITEMS
} from '../../graphql';
import MapActionSideMenu from './MapActionSideMenu';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import checkTypeName, { RemoteTypeNames } from '../../utils/checkTypeName';
import { SideMenuStep } from '../../utils/model';
import { message } from 'antd';

const MapContainer = (props) => {
  const [orderId, setOrderId] = useState(null);
  const [itemIds, setItemIds] = useState(null);
  const {t} = useTranslation();
  const [stateProps, setState] = useState({
    isLoaded: false,
    placesList: {},
  });
  const handleOrderData = (orderId, itemIds) => {
    setOrderId(orderId);
    setItemIds(itemIds);
  };
  const setLoading = () => {
    props.dispatch(props.clearTickets());
    setState({
      ...stateProps,
      isLoaded: false,
    });
  };
  const screenWidth = document.body.offsetWidth;
  const initialZoom = screenWidth <= 768 ? 2 : 3;

  const removeTicket = (id) => {
    props.dispatch(props.removeTicket(id));
    props.mapPlaces.forEach((place) => {
      if (place.options.id === id) {
        place.setStyle({
          fillColor: place.options.originalColor,
          weight: 0,
          isSelected: false,
        });
      }
    });
  };
  const [menuOpened, setMenuOpened] = useState(false);
  const onClose = () => {
    setMenuOpened(true)
    props.dispatch(props.clearTickets());
    setSideMenuStep(SideMenuStep.DEFAULT);
    props.mapPlaces.forEach((place) => {
      if (place.options.isSelected) {
        place.setStyle({
          fillColor: place.options.originalColor,
          weight: 0,
          isSelected: false,
        });
      }
    });
    setMenuOpened(false)
    if (orderId && itemIds && itemIds.length > 0) {
      cancelReservation(orderId, itemIds);
    } 
  };

  const cancelReservation = async (orderId, orderItemIds) => {
    if (!orderId || !orderItemIds || !Array.isArray(orderItemIds) || orderItemIds.length === 0) {
      return;
    }
    try {
      await props.client.query({
        query: CANCEL_RESERVATION,
        variables: {
          orderId,
          orderItemIds: orderItemIds.map(item => item.id),
        },
        fetchPolicy: 'no-cache',
      });
      setOrderId(null);
      setItemIds(null);
      message.success(t('modules.map_action_side_menu.message_success.order_reservation_cancelled'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.map_action_side_menu.message_error.reservation_cancelling_error'));
    }
  };

  useEffect(() => {
    if (!props.selectedPlaces.length && menuOpened) {
      cancelReservation(orderId, itemIds);
    }
  }, [menuOpened]);

  const setSelectedPlaces = (placeIds) => {
    if (placeIds && placeIds.length) {
      let newPlacesData = [];
      props.dispatch(props.clearTickets());
      const places = placeIds.map((id) => props.mapPlaces.find(place => place.options.id === id));
      const alreadySelectedPlace = places.find(place => place.options.isSelected);
      const newStyle = {
        color: alreadySelectedPlace.options.originalColor,
        fillColor: alreadySelectedPlace.options.fillColor,
        weight: alreadySelectedPlace.options.weight,
        isSelected: true,
      };

      places.forEach((place) => {
        place.setStyle(newStyle);

        newPlacesData.push({
          id: place.options.id,
          number: place.options.number,
          row: place.options.row,
          sector: place.options.sector,
          price: place.options.price,
          place,
        })
      });
      props.dispatch(props.addTicketArray(newPlacesData));
    } else {
      setSideMenuStep(SideMenuStep.DEFAULT);
    }
  };

  const setSideMenuStep = (step) => {
    props.dispatch(props.setSideMenuStep(step));
  }

  useEffect(() => {
    const init = async () => {
      props.dispatch(props.clearTickets());
      const event = props.event;
      const status = pathOr('', ['status'], event);
      const seasonTicketTitle = pathOr('', ['title'], event);

      const id = pathOr('', ['id'], event);
      const typename = pathOr('', ['__typename'], event);

      const team1Title = pathOr('', ['team1', 'title'], event);
      const team1Image = pathOr('', ['team1', 'logo', 'publicLink'], event);
      const team2Title = pathOr('', ['team2', 'title'], event);
      const team2Image = pathOr('', ['team2', 'logo', 'publicLink'], event);
      const imageUrl = pathOr('', ['venue', 'map', 'publicLink'], event);
      const mapSize = pathOr('', ['venue', 'size'], event);

      const isMatch = checkTypeName(event, RemoteTypeNames.MATCH);
      const placesPath = isMatch
        ? ['price', 'getPlacesDetailed', 'list']
        : ['seasonTicketDescriptor', 'getById', 'places'];
      const placesQueryObj = isMatch
        ? {
          query: GET_MATCH_PLACES,
          variables: {
            filter: {
              tag: 'ONLINE',
              eventId: id,
            },
            paging: {limit: 10000},
          },
        }
        : {
          query: GET_SEASON_PLACES,
          variables: {
            id: id,
          },
        };

      const placesRes = await props.client.query({
        query: placesQueryObj.query,
        variables: placesQueryObj.variables,
        fetchPolicy: 'no-cache',
      });

      const placesList = pathOr([], placesPath, placesRes.data);
      const ticketsQueryObj = isMatch
        ? {
          query: GET_TICKET_ORDER_ITEMS,
          variables: {
            filter: {
              eventIds: [props.event.id],
              status: ['SOLD', 'RESERVED']
            },
            paging: {limit: 10000},
          },
        }
        : {
          query: GET_SEASON_TICKET_ORDER_ITEMS,
          variables: {
            filter: {
              seasonTicketDescriptorIds: [props.event.id],
              statuses: ['SOLD', 'RESERVED'],
            },
            paging: {limit: 10000},
          },
        };

      const ticketsRes = await props.client.query({
        query: ticketsQueryObj.query,
        fetchPolicy: 'no-cache',
        variables: ticketsQueryObj.variables,
      });

      const ticketsPath = [isMatch ? 'ticket' : 'seasonTicket', 'getList', 'list'];
      const ticketsList = pathOr([], ticketsPath, ticketsRes.data)

      ticketsList.forEach(ticket => {
        const ticketId = pathOr('', ['id'], ticket);
        const ticketPlaceId = pathOr('', ['place', 'id'], ticket);
        const orderId = pathOr('', ['orderItem', 'order', 'id'], ticket);
        const orderItemId = pathOr('', ['orderItem', 'id'], ticket);
        const orderType = pathOr('', ['orderItem', 'type'], ticket);
        const user = pathOr('', ['user'], ticket)
        Object.assign(
          placesList.find(el => el.place.id === ticketPlaceId),
          {
            ticketId,
            orderId,
            orderItemId,
            orderType,
            user,
          }
        )
      })

      setState({
        ...stateProps,
        imageUrl,
        mapSize,
        placesList,
        isLoaded: true,
        eventData: {
          id,
          typename,
          team1Title,
          team1Image,
          team2Title,
          team2Image,
          seasonTicketTitle,
          status,
        },
        isMatch,
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    return function cleanUp() {
      props.dispatch(props.setSelectedPlaces([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  return stateProps.isLoaded === false ? (
    <div className="with-preloader__map">
      <LoadingOutlined />
      <div className="with-preloader__info">
        <div>{t('modules.map_container.arena_loading')}</div>
      </div>
    </div>
  ) : (
    <>
      <MapComponent
        containerId={'map'}
        placesList={stateProps.placesList}
        selectedPlaces={stateProps.selectedPlaces}
        overlayUrl={stateProps.imageUrl}
        overlaySize={stateProps.mapSize}
        maxSelection={999}
        placeRadius={Number(window.parametrize('REACT_APP_MAP_PLACE_RADIUS'))}
        weight={Number(window.parametrize('REACT_APP_MAP_PLACE_WEIGHT'))}
        initialZoom={initialZoom}
        minZoom={initialZoom}
        maxZoom={Number(window.parametrize('REACT_APP_MAP_MAX_ZOOM'))}
        canvasTolerance={5}
        clickableFromZoom={5}
        remoteHandler={() => {
        }}
        eventHandler={(ev) => {
          if (ev.type === 'mapInit') {
            props.dispatch(props.setPlaces(ev.data.places));
          }
          if (ev.type === 'placeRemove') {
            props.dispatch(props.removeTicket(ev.data.id));
            if (!props.selectedPlaces.length) {
              props.dispatch(props.setSideMenuStep(SideMenuStep.DEFAULT))
            }
          }
          if (ev.type === 'placeSelect') {
            props.dispatch(props.addTicket(ev.data));
          }
          if (ev.type === 'placeArraySelect') {
            props.dispatch(props.addTicketArray(ev.data));
          }
        }}
      />

      <div className={styles.statusFooter}>
        <div className={styles.statusFooter__item}>
          <div className={styles.statusFooter__item__title}>{t('modules.map_container.free')}</div>
          <div className={styles.statusFooter__item__value}>
            {
              (isEmpty(props.selectedPlaces) ? stateProps.placesList : props.selectedPlaces).filter((item) => {
                return isEmpty(props.selectedPlaces)
                  ? item.status === 'ACTIVE'
                  : item.place.options.status === 'ACTIVE';
              }).length
            }
          </div>
        </div>
        <div className={styles.statusFooter__item}>
          <div
            className={styles.statusFooter__item__circle}
            style={{
              backgroundColor: '#5C5C5C',
            }}
          />
          <div className={styles.statusFooter__item__title}>{t('modules.map_container.booked')}</div>
          <div className={styles.statusFooter__item__value}>
            {
              (isEmpty(props.selectedPlaces) ? stateProps.placesList : props.selectedPlaces).filter((item) => {
                return isEmpty(props.selectedPlaces)
                  ? item.status === 'RESERVED'
                  : item.place.options.status === 'RESERVED';
              }).length
            }
          </div>
        </div>
        <div className={styles.statusFooter__item}>
          <div
            className={styles.statusFooter__item__circle}
            style={{
              backgroundColor: '#C4C4C4',
            }}
          />
          <div className={styles.statusFooter__item__title}>{t('modules.map_container.blocked')}</div>
          <div className={styles.statusFooter__item__value}>
            {
              (isEmpty(props.selectedPlaces) ? stateProps.placesList : props.selectedPlaces).filter((item) => {
                return isEmpty(props.selectedPlaces)
                  ? item.status === 'BLOCKED'
                  : item.place.options.status === 'BLOCKED';
              }).length
            }
          </div>
        </div>
        <div className={styles.statusFooter__item}>
          <div
            className={styles.statusFooter__item__circle}
            style={{
              backgroundColor: '#C4C4C4',
              width: '8px',
              height: '8px',
            }}
          />
          <div className={styles.statusFooter__item__title}>{t('modules.map_container.bought')}</div>
          <div className={styles.statusFooter__item__value}>
            {
              (isEmpty(props.selectedPlaces) ? stateProps.placesList : props.selectedPlaces).filter((item) => {
                return isEmpty(props.selectedPlaces) ? item.status === 'SOLD' : item.place.options.status === 'SOLD';
              }).length
            }
          </div>
        </div>
      </div>
      {props.selectedPlaces.length ? (
        <MapActionSideMenu
          handleOrderData={handleOrderData}
          removeTicket={(id) => {
            removeTicket(id);
          }}
          setSelectedPlaces={(ids, status) => setSelectedPlaces(ids)}
          setStep={(step) => setSideMenuStep(step)}
          step={props.mapSideMenuStep}
          authClient={props.authClient}
          selectedPlaces={props.selectedPlaces}
          client={props.client}
          isMatch={stateProps.isMatch}
          onClose={() => {
            onClose();
          }}
          status={stateProps.eventData.status}
          setLoading={() => {
            setLoading();
          }}
          event={props.event}
          onOpen={() => setMenuOpened(true)}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default compose(connect)(MapContainer);
