import React, { useState } from 'react';
import './Referral.scss';

import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import AuthClient from "@sebbia/auth-client/lib/AuthClient";

import Loader from 'common/Loader/Loader';
import ReferralPage from '../components/ReferralPage/ReferralPage';

function Referral(props: {
  authClient: AuthClient,
  client: ApolloClient<any>,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? <Loader /> : <ReferralPage authClient={props.authClient} client={props.client} />}
    </>
  );
}

export default withApollo(Referral);
