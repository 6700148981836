import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import pathOr from 'ramda/es/pathOr';
import PromocodeDetails from '../../PromocodeDetails';
import InputComponent from '../../../../../../tickets/src/Tickets/components/InputComponent';
import styles from './input.module.scss';
import { GET_PROMOCODES } from '../graphql';
import { getMoscowTime } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const PromoBody = (props) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const columns = props.getColumns();

  const handleSearch = async (title) => {
    setLoading(true);
    const res = await props.client.query({
      query: GET_PROMOCODES,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          search: title || undefined,
        },
      },
    });

    const promocodeList = pathOr([], ['data', 'promocodes', 'getList', 'list'], res);

    const arr = await Promise.all(
      promocodeList.map(async (item) => {
        const saleValue = pathOr('null', ['discount', 'value'], item);
        const discountType = pathOr('', ['discount', 'type'], item);
        let saleSuffix;
        switch (discountType) {
          case 'PERCENT':
            saleSuffix = '%';
            break;
          case 'FIXED_PRICE':
            saleSuffix = 'f.';
            break;
          case 'ABSOLUTE':
            saleSuffix = t('currency');
            break;
          default:
            saleSuffix = '';
            break;
        }
        const saleString = `${saleValue} ${saleSuffix}`;

        const period = {
          start: pathOr(null, ['period', 'start'], item),
          end: pathOr(null, ['period', 'end'], item),
        };

        const isFinite = period.start && period.end;

        const periodValue = isFinite
          ? [getMoscowTime(period.start, 'dd.MM.yyyy'), getMoscowTime(period.end, 'dd.MM.yyyy')]
          : '∞';

        const codes = pathOr([], ['codes', 'list'], item);

        const count = codes.map((item) => item.useCounter).reduce((acc, curr) => acc + curr, 0);
        const limit = pathOr(false, ['maxUseCounts'], item);
        const bounds = await props.getPromoBounds(item);
        const endOfCodeName = codes.length > 1 ? '...' : '';
        const activationsString = limit ? `${count} ${t('modules.promo_body.activations_string')} ${limit}` : count;
        return {
          id: item.id,
          title: item.title,
          sale: saleString,
          period: periodValue,
          activations: activationsString,
          group: item.adminUseOnly ? t('modules.promo_body.group.administrator') : t('modules.promo_body.group.all'),
          code: codes.length > 0 ? codes[0].value + endOfCodeName : t('modules.promo_body.code'),
          bounds,
          commentary: item.commentary,
          initial: item,
        };
      })
    );

    setList(arr);
    setLoading(false);
  };

  useEffect(() => {
    setList(pathOr([], ['promoList'], props));
  }, [props.promoList]);

  return (
    <>
      <InputComponent
        handleSearchChange={(inputValue) => {
          handleSearch(inputValue);
        }}
        inputContainerStyle={{}}
        inputClassName={styles.input}
        inputTimeout={700}
        suffixIconType="search"
        iconOnClick={() => {}}
        allowClear={true}
        iconStyle={{
          color: 'grey',
          cursor: 'default',
        }}
        inputPlaceholder={t('modules.promo_body.input_component')}
      />
      <div className="promocode__item">
        <Table
          dataSource={list}
          columns={columns}
          loading={isLoading}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowRender: (record) => <PromocodeDetails record={record} />,
            rowExpandable: () => true,
          }}
        />
      </div>
    </>
  );
};

export default PromoBody;
