import React from 'react';
import InputCondition from './InputCondition';
import { useTranslation } from 'react-i18next';

const ConditionDetails = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="referral-details">
      <p className="referral-condition">
        {t('modules.referral_conditions.condition_one.text')}
        <InputCondition
          value={props.accrualPercentage}
          t={t}
          onChange={(value) => props.setAccrualPercentage(value)}
          maxValidValue={100}
        />
        {t('modules.referral_conditions.condition_one.desc')}
      </p>
      <p className="referral-condition">
        {t('modules.referral_conditions.condition_two.text')}
        <InputCondition
          value={props.referralBonus}
          t={t}
          onChange={(value) => props.setReferralBonus(value)}
          maxValidValue={2000}
        />
        {t('modules.referral_conditions.condition_two.desc')}
      </p>
    </div>
  );
};

export default ConditionDetails;
