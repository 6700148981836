import React, { useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Row, Col, Button, Divider, Select, Empty, Tag } from 'antd';
import map from 'ramda/es/map';
import when from 'ramda/es/when';
import propEq from 'ramda/es/propEq';
import assoc from 'ramda/es/assoc';
import PromoAddModal from './PromoAddModal';
import pathOr from 'ramda/es/pathOr';
import { useTranslation } from 'react-i18next';
import { setDeletedBoundValue } from '../containers/deletedBoundStore';
import uuidv1 from 'uuid/v1';
const { Option } = Select;

const PromoAddBounds = (props) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = props;
  const [bounds, setBounds] = useState([]);
  const promoBounds = [
    t('modules.constants_app.local'),
    t('modules.constants_app.by_row'),
    t('modules.constants_app.by_sector'),
    t('modules.constants_app.by_tournament'),
    t('modules.constants_app.by_stage'),
    t('modules.constants_app.by_season'),
    t('modules.constants_app.by_price'),
    t('modules.constants_app.by_order_type'),
    t('modules.constants_app.by_user'),
    t('modules.constants_app.by_number_items_order'),
    t('modules.constants_app.by_matches_subscriptions'),
  ];

  const updateBound = (id, type, value) => {
    const updatedBounds = map(when(propEq('id', id), assoc(type, value)), bounds);
    setFieldValue('bounds', updatedBounds);
  };
  const handleAddNewBound = () => {
    const newBound = {
      id: uuidv1(),
      type: '0',
      condition: '0',
      values: [],
    };

    const updatedBounds = [...bounds, newBound];
    setBounds(updatedBounds);
    setFieldValue('bounds', updatedBounds);
  };
  const [deletedBound, setDeletedBound] = useState([]);
  const handleDeleteBound = (boundId) => {
    const updatedBounds = bounds.filter((item) => item.id === boundId);
    setDeletedBound([...deletedBound, ...updatedBounds]);
    const newDeletedBound = [...deletedBound, ...updatedBounds];
    setDeletedBoundValue(newDeletedBound);
    setFieldValue(
      'bounds',
      bounds.filter((item) => item.id !== boundId)
    );
  };
  useEffect(() => {
    const getBounds = async () => {
      const result = await values.bounds;
      if (Array.isArray(result)) setBounds(result);
    };
    getBounds();
  }, [values.bounds]);
  return (
    <div className="promo-add__bound-list">
      <Card title={t('modules.promo_add_bounds.card')}>
        {bounds.length > 0 ? (
          bounds.map((bound) => {
            const boundValues = pathOr([], ['value'], bound);
            const boundType = pathOr('', ['type'], bound);
            const boundTitle = pathOr('', ['title'], bound);
            let rangeTypeValue = '';
            const isRangeType =
              boundType === '6' ||
              boundType === '9' ||
              boundTitle.includes(t('modules.promo_add_bounds.is_range_type.price')) ||
              boundTitle.includes(t('modules.promo_add_bounds.is_range_type.items_order'));
            try {
              rangeTypeValue = `${t('modules.promo_add_bounds.range_type_value.from')} ${bound.values[0].title} ${t(
                'modules.promo_add_bounds.range_type_value.to'
              )} ${bound.values[1].title}`;
            } catch (err) {
              rangeTypeValue = bound.value;
            }
            return (
              <div className="promo-add__bound-item" key={bound.id}>
                <Button
                  id="PromoAddBoundsDeleteOutlinedButton"
                  className="promo-add__bound-remove"
                  shape="circle"
                  type="link"
                  onClick={() => handleDeleteBound(bound.id)}
                >
                  <DeleteOutlined />
                </Button>
                <Row>
                  <Col span={8}>
                    <Form.Item label={t('modules.promo_add_bounds.form_item.bound_type')}>
                      <Select
                        defaultValue={bound.type || '0'}
                        value={bound.type || '0'}
                        onChange={(e) => updateBound(bound.id, 'type', e)}
                      >
                        {promoBounds.map((type, index) => (
                          <Option key={index} value={index.toString()}>
                            {' '}
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t('modules.promo_add_bounds.form_item.condition')}>
                      <Select
                        defaultValue={bound.condition || '0'}
                        value={bound.condition || '0'}
                        disabled={false}
                        onChange={(e) => updateBound(bound.id, 'condition', e)}
                      >
                        <Option key="0" value="0">
                          {t('modules.promo_add_bounds.option.including')}
                        </Option>
                        <Option key="1" value="1">
                          {t('modules.promo_add_bounds.option.everything_except')}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <Form.Item label={t('modules.promo_add_bounds.form_item.positions')}>
                      <div className="promo-add__bound-positions">
                        {isRangeType && rangeTypeValue ? (
                          <Tag>{rangeTypeValue}</Tag>
                        ) : (
                          <>
                            {!bound?.values &&
                              boundValues.length > 0 &&
                              boundValues.map((item, idx) => <Tag key={`bound-${idx}`}>{item}</Tag>)}
                            {bound?.values &&
                              bound.values.map((item) => <Tag key={`value-${item.id}`}>{item.title}</Tag>)}
                          </>
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Button
                      id="PromoAddBoundsChooseButton"
                      type="default"
                      onClick={() => props.handleBoundPositionsSelect(bound)}
                    >
                      {t('modules.promo_add_bounds.button.choose')}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })
        ) : (
          <Empty description={t('modules.promo_add_bounds.empty')} />
        )}
        <Divider />
        <Button id="PromoAddBoundsAddConstraintButton" size="large" type="link" onClick={handleAddNewBound}>
          <PlusOutlined />
          {t('modules.promo_add_bounds.button.add_bound')}
        </Button>
      </Card>
      <PromoAddModal
        boundItem={values.currentBound}
        visible={values.isModalVisible}
        onClose={props.handleModalClose}
        setFieldValue={setFieldValue}
        values={values}
      />
    </div>
  );
};

export default PromoAddBounds;
