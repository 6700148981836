import gql from 'graphql-tag';

export const GET_REFERRAL_ACTIVE_STATUS = gql`
  query {
    referralDescriptor {
      getDescriptor {
        id
        accrualPercentage
        referralBonus
        isActive
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
