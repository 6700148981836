import React, { useState, useEffect } from 'react';
import pathOr from 'ramda/es/pathOr';

const PromocodeDetails = (props) => {
  const { listTitles } = props;
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchBounds = async () => {
      const boundsPromise = pathOr(Promise.resolve([]), ['record', 'bounds'], props);
      const resolvedBounds = await boundsPromise;
      setList(resolvedBounds);
    };
    fetchBounds();
  }, [props]);
  return (
    <ul>
      <li className="df aifs">
        <h5>{listTitles[0].title}</h5>
        <span className="promocode__bound-wrapper df fww">
          {list.map((item, index) => {
            const title = item.title;
            const value = pathOr([], ['value'], item);
            return (
              <span key={index} className="promocode__bound-item df">
                <span className="promocode__bound-kind">{title}</span>
                <span className="promocode__bound-value">{Array.isArray(value) ? value.join(', ') : value}</span>
              </span>
            );
          })}
        </span>
      </li>
      <li className="df">
        <h5>{listTitles[1].title}</h5>
        <span>{pathOr('', ['record', 'commentary'], props)}</span>
      </li>
    </ul>
  );
};

export default PromocodeDetails;
