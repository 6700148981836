import gql from 'graphql-tag';

export const CHANGE_REFERRAL_RULES = gql`
  mutation($data: ReferralDescriptorUpdateInput!) {
    referralDescriptor {
      update(data: $data) {
        accrualPercentage
        referralBonus
        isActive
      }
    }
  }
`;
