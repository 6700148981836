import React from 'react';
import { Button, Modal } from 'antd';
import { Icon } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';

const PopUpComponent = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={false}
      closable={false}
      visible={props.isVisible}
      footer={[
        <Button
          id="ModalDeactivationWarningDeactivateButton"
          key="deactivate"
          className="route-content_loyalty-deactivate-button"
          onClick={() => {
            props.setIsVisible(false);
            props.deactivateReferral();
          }}
        >
          {t('modules.conditions_buttons.text')}
        </Button>,
        <Button
          id="ModalDeactivationWarningCancelButton"
          key="cancel"
          className="route-content_loyalty-cancel-button"
          type="primary"
          onClick={() => props.setIsVisible(false)}
        >
          {t('modules.conditions_buttons.cancel_button')}
        </Button>,
      ]}
      width={415}
    >
      <div className="route-content_loyalty-modal-title-block">
        <Icon type="close-circle" className="route-content_loyalty-close-circle" />
        <h3 className="route-content_loyalty-modal-title">{t('modules.conditions_buttons.modal.title')}</h3>
      </div>
      <p className="route-content_loyalty-modal-text">{t('modules.conditions_buttons.modal.desc')}</p>
    </Modal>
  );
};

export default PopUpComponent;
