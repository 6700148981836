import { withHandlers, compose } from 'recompose';
import { pathOr, uniqBy, prop, assoc } from 'ramda';
import i18next from 'i18next';

export default compose(
  withHandlers({
    handleSelectBoundItems: (props) => async (strValues) => {
      const bounds = await props.values.bounds;
      const boundItem = bounds.find((item) => item.id === props.boundItem.id);
      const values = strValues.map((item) => JSON.parse(item));
      const updated = assoc('values', values, boundItem);
      const updatedBounds = uniqBy(prop('id'), [updated, ...bounds]);
      props.setFieldValue('bounds', updatedBounds);
    },
    handleSelectSeason: (props) => async (seasonValue) => {
      const bounds = await props.values.bounds;
      const boundItem = bounds.find((item) => item.id === props.boundItem.id);
      const value = JSON.parse(seasonValue);
      const updated = assoc('season', value, boundItem);
      const updatedBounds = uniqBy(prop('id'), [updated, ...props.values.bounds]);

      props.setFieldValue('bounds', updatedBounds);
    },
    getPlaceList: (props) => (data) => {
      const list = pathOr([], ['place', 'getList', 'list'], data);
      const placeList = list.map((item) => ({
        id: item.row.id,
        title: `${item.row.sector.title}-${item.row.number}-${item.number}`,
      }));
      return placeList;
    },
    getRowList: (props) => (data) => {
      const list = pathOr([], ['place', 'getList', 'list'], data);
      const rowList = list.map((item) => ({
        id: item.row.id,
        title: `${item.row.sector.title}-${item.row.number}`,
      }));
      return uniqBy(prop('id'), rowList);
    },
    getSectorList: (props) => (data) => {
      const list = pathOr([], ['place', 'getList', 'list'], data);
      const defaultObj = { id: '-1', title: 'undefined sector' };
      const sectorList = list.map((item) => pathOr(defaultObj, ['row', 'sector'], item));
      return uniqBy(prop('id'), sectorList);
    },
    getTournamentList: (props) => (data) => {
      const list = pathOr([], ['tournament', 'getList', 'list'], data);
      return uniqBy(prop('id'), list);
    },
    getStageList: (props) => (data) => {
      const list = pathOr([], ['stage', 'getList', 'list'], data);
      return uniqBy(prop('id'), list);
    },
    getSeasonList: (props) => (data) => {
      const list = pathOr([], ['season', 'getList', 'list'], data);
      const seasonList = list.map((item) => {
        const startDate = `${new Intl.DateTimeFormat(i18next.language, { month: 'long' })
          .format(new Date(item.startDate))
          .toLowerCase()} ${new Date(item.startDate).getFullYear()}`;

        const endDate = `${new Intl.DateTimeFormat(i18next.language, { month: 'long' })
          .format(new Date(item.endDate))
          .toLowerCase()} ${new Date(item.endDate).getFullYear()}`;

        return {
          id: item.id,
          title: `${startDate} - ${endDate}`,
        };
      });
      return uniqBy(prop('id'), seasonList);
    },
    getUserList: (props) => (data) => {
      const list = pathOr([], ['users', 'getListAdvanced', 'list'], data);
      const userList = list.map((item) => ({
        id: item.id,
        title: item.login,
      }));
      return uniqBy(prop('id'), userList);
    },
    getEventsList: (props) => (list, type) => {
      const eventsList = list.map((item) => ({
        id: item.id,
        title: item.title,
        type,
      }));
      return eventsList;
    },
  }),
  withHandlers({
    handleChangePlace: (props) => (e) => {
      const value = e.target.value;
      if (value.includes('-')) {
        const placeList = props.getPlaceList(props.placeList.initial).filter((item) => item.title.includes(value));
        props.setPlaceList({ ...props.placeList, current: placeList });
      } else {
        props.setPlaceList({ ...props.placeList, current: [] });
      }
    },
  })
);
