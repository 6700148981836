import React from 'react';
import './ReferralActivate.scss';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { changeStatusReferral } from 'modules/referral/src/graphql/model/referralMutations';

const ReferralActivate = ({ authClient, client, ...props }) => {
  const { t } = useTranslation();

  async function activateReferral() {
    if (!props.isActive) {
      message.error(t('modules.referral_activate-modal.error'));
      return;
    }
    const data = { isActive: true };
    const activateReferral = await changeStatusReferral(client, data);
    
    if (activateReferral) {
      props.setShowConditions((prev) => ({ ...prev, isActive: activateReferral?.isActive || true }));
      message.success(t('modules.referral_activate-modal.success'));
    }
  }
  return (
    <div className="referral-activate">
      <div className="referral-activate__header">
        <p className="referral-activate__title">
          <span>{t('modules.referral_activate.0')}</span>
          <br />
          {t('modules.referral_activate.1')}
          <br />
          {t('modules.referral_activate.2')}
        </p>
        <p className="referral-activate__description">
          {t('modules.referral_activate_desc.0')}
          <br />
          {t('modules.referral_activate_desc.1')}
        </p>
      </div>
      <div className="referral-activate__box">
        <p className="bonus__description">{t('modules.benefits')}</p>
        <div className="referral-activate__bonus">
          <div className="referral-activate__bonus__item">
            <div className="disc"></div>
            {t('modules.benefits_activate')}
          </div>
          <div className="referral-activate__bonus__item">
            <div className="disc"></div>
            {t('modules.benefits_items.0')}
            <span>{t('modules.benefits_items.1')}</span>
          </div>
          <div className="referral-activate__bonus__item">
            <div className="disc"></div>
            <span>{t('modules.benefits_items.2')}</span>
            <p className="responsive-br">
              {t('modules.benefits_items.3')}
            </p>
          </div>
        </div>
      </div>
      <div className="referral-activate__footer">
        <button className="referral-activate-button" onClick={activateReferral}>
          {t('modules.activate_modal-title')}
        </button>
        <p className="bonus__description bonus__description-margin">
          {t('modules.activate_modal-condition.0')}
          <br className="responsive-br"/>
          {t('modules.activate_modal-condition.1')}
        </p>
      </div>
    </div>
  );
};

export default ReferralActivate;
