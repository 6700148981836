import { GET_ACTIVE_STATUS } from "modules/loyalty/src/Loyalty/model/loyaltyQueries";
import ApolloClient from "apollo-client";
import { GET_REFERRAL_ACTIVE_STATUS } from "../query";
import { pathOr } from "ramda";

export const getStatus = async (client: ApolloClient<any>) => {
  const response = await client.query({
    query: GET_ACTIVE_STATUS,
    fetchPolicy: 'no-cache',
  });
  const isActiveValue = pathOr(false, ['data', 'loyalty', 'loyaltyDescriptorQuery', 'getDescriptor', 'isActive'], response);
  return isActiveValue;
};
export const getStatusActiveReferral = async (client: ApolloClient<any>) => {
  const response = await client.query({
    query: GET_REFERRAL_ACTIVE_STATUS,
    fetchPolicy: 'no-cache',
  });
  const activateReferral = pathOr(
    {
      accrualPercentage: 0,
      referralBonus: 0,
      isActive: false,
    }, ['data', 'referralDescriptor', 'getDescriptor'], response);
  return activateReferral;
}